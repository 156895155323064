import React from "react"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import Layout from "../components/Layout";
import PageSection from "../components/PageSection";
import styles from "../css/blog-template.module.css";
import SocialShare from "../components/SocialShare";
import BlogItem from "../components/singles/BlogItem";

const Blog = ({ data, location }) => {

    const twitterUsername = "caspargeerlings";
    const {
        image,
        title,
        subheading,
        date,
        readingTime,
        metaDescription,
        tags,
    } = data.blog.frontmatter;

    const relatedPosts = data.blog.relatedPosts;

    return (
        <Layout>
            <SEO title={title} metaDescription={metaDescription} pageImage={image} pathname={location.pathname} type={"article"} keyword={tags[0].tag} readingTime={readingTime} />
            <div className={styles.headerContainer}>
                <img className={styles.image} src={image} alt={title} />
            </div>
            <PageSection>
                <article className={styles.blog}>
                    <h1 className={styles.title}>{title}</h1>
                    <h2>{subheading}</h2>
                    <div className={styles.authorContainer}>
                        <div className={styles.left}>
                            <div className={styles.authorBlock}>
                                <img
                                    src={"/caspar.png"}
                                    alt="Image of Caspar Geerlings"
                                    className={styles.author} />
                                <div>
                                    <div className="author-name">Caspar Geerlings</div>
                                    <div className={styles.authorSubtitle}>
                                        {new Date(date).toLocaleString("en-US", {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric'
                                        })} - {readingTime} min read
                                    </div>
                                </div>
                                <span className={styles.label}>{tags[0]}</span>
                            </div>

                        </div>
                        <div className={styles.share}>
                            <SocialShare title={title}
                                url={typeof window !== 'undefined' && window.location.href}
                                twitterHandle={twitterUsername}
                                tags={tags[0].tag} />
                        </div>
                    </div>
                    <div className={styles.content} dangerouslySetInnerHTML={{ __html: data.blog.html }} />
                </article>

                {relatedPosts !== 0 && relatedPosts !== null &&
                    <div className={styles.relatedArticles}>
                        <h1>Related Articles</h1>
                        <div className={styles.relatedArticlesGrid}>
                            {relatedPosts.sort((a, b) => (a.frontmatter.date < b.frontmatter.date) ? 1 : -1).slice(0, 2).map((blogItem) => {
                                return (
                                    <BlogItem blogItem={blogItem.frontmatter} />
                                )
                            })}
                        </div>
                    </div>
                }
            </PageSection>
        </Layout>
    )
};

export const pageQuery = graphql`
    query($slug: String) {
        blog: markdownRemark(fields:  {slug: {eq: $slug} }) {
            fields{
                slug
            }
            frontmatter {
                image
                title
                subheading
                date
                readingTime
                tags 
                metaDescription
            }
            id
            html
            relatedPosts {
                fields {
                  slug
                }
                frontmatter{
                    image
                    title
                    subheading
                    date
                    readingTime
                    tags
                    metaDescription
                    url
                    slug
                }
              }
        }
    }
`

export default Blog
import React from "react"

import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share"

import {FaFacebook, FaLinkedin, FaReddit, FaTwitter, FaWhatsapp} from 'react-icons/fa';

const SocialShare = ({title, url, twitterHandle, tags}) => {
    return (
        <div>
            <FacebookShareButton url={url}>
                <FaFacebook size={30} round={true}/>
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
                <FaTwitter size={30} round={true}/>
            </TwitterShareButton>

            <LinkedinShareButton url={url}>
                <FaLinkedin size={30} round={true}/>
            </LinkedinShareButton>

            <RedditShareButton url={url} title={title}>
                <FaReddit size={30} round={true}/>
            </RedditShareButton>

            <WhatsappShareButton url={url} title={title}>
                <FaWhatsapp size={30} round={true}/>
            </WhatsappShareButton>
        </div>
    )
};

export default SocialShare